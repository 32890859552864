import React from "react"
import tw from "twin.macro"
import Logo from "assets/svgs/logo-primary.svg"

function IndexPage() {
  return (
    <div className="h-screen flex items-center justify-center">
      <Logo css={tw`w-auto h-6`} />
    </div>
  )
}

export default IndexPage
